@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200&display=swap');

@import './tailwind-init.css';
@import './variables.scss';
@import './breakpoints.scss';
@import './components/header.scss';
.header {
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 98px;
  z-index: 4;
  @include media('<=phone') {
    height: 96px;
  }
  .logo {
    a {
      font-size: 1.2rem;
      letter-spacing: 0.5rem;
      color: $black;
      text-decoration: none;
      font-weight: 700;
    }
  }
  .nav-toggle {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media('<=tablet') {
      margin-right: 0;
    }
    @include media('<=phone') {
      margin-right: 16px;
      width: 20px;
    }
    .hamburger-menu {
      width: 25px;
      span {
        margin-bottom: 0.3rem;
        background: $black;
        height: 2px;
        width: 100%;
        display: block;
      }
    }
    .hamburger-menu-close {
      position: absolute;
      display: none;
      svg {
        @include media('<=tablet') {
          width: 56px;
        }
        @include media('<=phone') {
          width: 48px;
        }
      }
    }
  }
}


@import './components/banner.scss';
.main {
  height: 70vh; // fallback option
  height: calc(var(--vh, 1vh) * 100);
  background-color: $white;
  &.service-page {
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: '';
      backdrop-filter: blur(5px);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .row {
    flex-direction: column;
    justify-content: center;
    h1, h2 {
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 700;
      z-index: 10;
      color: $white;
      margin: 0;
      mix-blend-mode: difference;
      @include media('<=tablet') {
        font-size: 2.4rem;
        line-height: 3rem;
      }
      @include media('<=phone') {
        margin-top: 72px;
        font-size: 1.7rem;
        line-height: 2.2rem;
      }
      .line {
        margin-bottom: 0px;
        height: 56px;
        position: relative;
        overflow: hidden;
        @include media('<=tablet') {
          height: 48px;
        }
        @include media('<=tablet') {
          height: 32px;
        }
        span {
          position: absolute;
        }
      }
    }
    h1 {
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;
      @include media('<=tablet') {
        font-size: 1.7rem;
        line-height: 2rem;
      }
    }
    h2 .line {
      height: 42px;
    }
    .btn-row {
      position: relative;
      margin-top: 2.2rem;
      z-index: 2;
      a {
        font-size: 1.6rem;
        color: $black;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        @include media('<=tablet') {
          font-size: 1.3rem;
        }
        @include media('<=phone') {
          font-size: 1.1rem;
        }
        svg {
          margin-left: 16px;
          border: 2px solid $black;
          padding: 12px;
          height: 24px;
          width: 24px;
          border-radius: 100px;
          transition: 0.4s ease-in-out;
          @include media('<=tablet') {
            padding: 6px;
            width: 20px;
            height: 20px;
          }
          @include media('<=phone') {
            padding: 4px;
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          svg {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }
}

@import './components/cases.scss';
.cases {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  .cases-navigation {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 6;
    padding: 32px;
    box-sizing: border-box;
    @include media('<=tablet') {
      display: none;
    }
    .cases-arrow {
      background: rgba(0, 0, 0, 0.4);
      height: 72px;
      width: 72px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $white;
        height: 28px;
        width: 28px;
      }
      &.disabled {
        opacity: 0.3;
      }
    }
  }
  .row {
    @include media('<=tablet') {
      flex-direction: column;
    }
    .case {
      position: relative;
      background: $black;
      cursor: pointer;
      &:hover {
        .case-image {
          img {
            transform: scale(1.5) translateY(0px);
            transition: .6s;
          }
        }
      }
      .case-details {
        width: 33.333vw;
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 36px;
        box-sizing: border-box;
        z-index: 1;
        position: relative;
        @include media('<=tablet') {
          width: 100vw;
        }
        @include media('<=phone') {
          padding: 16px;
        }
        span.case-subtitle {
          font-size: 1.8rem;
          opacity: 1;
          color: $white;
          font-weight: bold;
        }
        span.case-title {
          margin-top: 10px;
          font-size: 1.4rem;
          line-height: 1.6rem;
          opacity: 0.8;
          color: $white;
          font-weight: 400;
          @include media('<=desktop', '>tablet') {
            font-size: 1.4rem;
          }
          @include media('<=phone') {
            font-size: 1.2rem;
            line-height: 2.2rem;
          }
        }
        h2 {
          font-size: 2.4rem;
          line-height: 2.6rem;
          width: 100%;
          margin-top: 16px;
          color: $white;
          @include media('<=desktop', '>tablet') {
            font-size: 2rem;
            line-height: 2.4rem;
          }
          @include media('<=phone') {
            font-size: 1.7rem;
            line-height: 2.2rem;
            margin-top: 8px;
          }
        }
      }
      .case-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        opacity: 0.45;
        transition: 0.4s cubic-bezier(0.6, -0.05, 0.1, 0.99);
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@import './components/navigation.scss';

body,
html {
  visibility: hidden;
  user-select: none;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media('<=1560px') {
    width: 1280px;
    min-width: 1280px;
  }
  @include media('<=desktop') {
    width: 1080px;
    min-width: 1080px;
  }
  @include media('<=1080px') {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media('<=phone') {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}

// intro overlay
.intro-overlay {
  &.service-page {
    .top {
      height: calc(var(--vh, 1vh) * 80);
    }
  }
  .top {
    height: 70vh;
    height: calc(var(--vh, 1vh) * 70);
    position: absolute;
    width: 100%;
    z-index: 8;
    .overlay-top {
      position: absolute;
      height: 100%;
      width: 33.3333vw;
      background: $black;
      bottom: 0;
      left: 0;
      right: 0;
      @include media('<=tablet') {
        width: 100vw;
      }
      &:nth-child(2) {
        left: 33.3333%;
        @include media('<=tablet') {
          display: none;
        }
      }
      &:nth-child(3) {
        left: 66.6666%;
        @include media('<=tablet') {
          display: none;
        }
      }
    }
  }
  .bottom {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 8;
    .overlay-bottom {
      position: absolute;
      height: 100%;
      width: 33.3333vw;
      background: $black;
      bottom: 0;
      right: 66.6666%;
      @include media('<=tablet') {
        right: 0;
        width: 100vw;
      }
      &:nth-child(2) {
        right: 33.3333%;
        @include media('<=tablet') {
          width: 100vw;
          top: 100%;
          right: 0;
        }
      }
      &:nth-child(3) {
        right: 0;
        @include media('<=tablet') {
          width: 100vw;
          top: 200%;
        }
      }
    }
  }
}

.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
  background: $white;
}

.page {
  min-height: 100vh;
  width: 100%;
  background: $white;
  display: flex;
  align-items: center;
}
