.main {
  height: 70vh; // fallback option
  height: calc(var(--vh, 1vh) * 70);
  background-color: $white;
  &.service-page {
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: '';
      backdrop-filter: blur(5px);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .row {
    flex-direction: column;
    justify-content: center;
    h1, h2 {
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 700;
      z-index: 10;
      color: $white;
      margin: 0;
      mix-blend-mode: difference;
      @include media('<=tablet') {
        font-size: 2.4rem;
        line-height: 3rem;
      }
      @include media('<=phone') {
        margin-top: 72px;
        font-size: 1.7rem;
        line-height: 2.2rem;
      }
      .line {
        margin-bottom: 0px;
        height: 56px;
        position: relative;
        overflow: hidden;
        @include media('<=tablet') {
          height: 48px;
        }
        @include media('<=phone') {
          height: 32px;
        }
        span {
          position: absolute;
        }
      }
    }
    h1 {
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;
    }
    h2 .line {
      height: 42px;
    }
    .btn-row {
      position: relative;
      margin-top: 2.2rem;
      z-index: 2;
      a {
        font-size: 1.6rem;
        color: $black;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        @include media('<=tablet') {
          font-size: 1.3rem;
        }
        @include media('<=phone') {
          font-size: 1.1rem;
        }
        svg {
          margin-left: 16px;
          border: 2px solid $black;
          padding: 12px;
          height: 24px;
          width: 24px;
          border-radius: 100px;
          transition: 0.4s ease-in-out;
          @include media('<=tablet') {
            padding: 6px;
            width: 20px;
            height: 20px;
          }
          @include media('<=phone') {
            padding: 4px;
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          svg {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }
}
